import styled from "@emotion/styled";
import { Link, useParams } from "react-router-dom";
import { COLORS, SIZES, WEIGHTS } from "constants";
import LessonList from "components/LessonList";
import { ReactComponent as RubyIcon } from "assets/icons/ruby-icon.svg";
import { ReactComponent as RailsIcon } from "assets/icons/rails-icon.svg";
import { ReactComponent as HtmlCssIcon } from "assets/icons/html-css-icon.svg";
import { ReactComponent as JavascriptIcon } from "assets/icons/javascript-icon.svg";
import { ReactComponent as ReactIcon } from "assets/icons/react-icon.svg";

const icons = {
  ruby: (
    <RubyIcon
      fill={`hsla(${COLORS.ruby}`}
      width="24px"
      height="24px"
      alt="ruby icon"
    />
  ),
  rails: (
    <RailsIcon
      fill={`hsla(${COLORS.rails}`}
      width="24px"
      height="24px"
      alt="rails icon"
    />
  ),
  "html-css": (
    <HtmlCssIcon
      fill={`hsla(${COLORS.htmlCss}`}
      width="24px"
      height="24px"
      alt="html-css icon"
    />
  ),
  javascript: (
    <JavascriptIcon
      fill={`hsla(${COLORS.js}`}
      width="24px"
      height="24px"
      alt="javascript icon"
    />
  ),
  react: (
    <ReactIcon
      fill={`hsla(${COLORS.react}`}
      width="24px"
      height="24px"
      alt="react icon"
    />
  ),
};

function UnitNavBar({ unit }) {
  const params = useParams();
  const icon = icons[unit.moduleSlug];

  return (
    <Wrapper>
      <StyledLink
        to={`/${params.cohort}/${params.program}/modules/${params.module}`}
      >
        <p>Back to</p>
        <StyledModule>
          {icon}
          <p>{unit.moduleTitle}</p>
        </StyledModule>
      </StyledLink>

      <StyledLesson>
        <p>{unit.label}</p>
        <h4>{unit.title}</h4>
      </StyledLesson>

      <LessonList lessons={unit.lessons} />
    </Wrapper>
  );
}

export default UnitNavBar;

const Wrapper = styled.div`
  display: grid;
  position: sticky;
  top: 0;
  grid-template-rows: repeat(auto-fill, minmax(min(48px, 100%), 1fr));
  grid-gap: 16px;
  padding: 24px 16px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  color: hsla(${COLORS.text});
  border: none;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  text-decoration: none;

  p {
    font-size: ${SIZES.sm};
    font-weight: ${WEIGHTS.light};
  }

  &:hover {
    background-color: hsla(${COLORS.primary}, 0.2);
  }
`;

const StyledModule = styled.div`
  display: flex;
  gap: 10px;
  p {
    font-size: ${SIZES.lg};
    font-weight: ${WEIGHTS.bold};
  }
`;

const StyledLesson = styled.div`
  display: flex;
  flex-direction: column;
  p {
    color: hsla(${COLORS.tertiary});
    font-size: ${SIZES.sm};
    font-weight: ${WEIGHTS.bold};
  }
  h4 {
    color: hsla(${COLORS.text});
    font-size: ${SIZES.lg};
    font-weight: ${WEIGHTS.medium};
  }
`;
