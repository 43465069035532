import styled from "@emotion/styled";
import Footer from "components/Footer";
import Header from "components/Header";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <Layout>
      <Header />
      <Main>
        <Outlet />
      </Main>
      <Footer />
    </Layout>
  );
}

export default MainLayout;

const Layout = styled.div`
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const Main = styled.main`
  width: 100%;
  height: 100%;
`;
