import { tokenKey } from "../config";
import apiFetch from "./api-fetch";

export function signIn(credentials) {
  return apiFetch("/users/sign_in", { body: credentials }).then((u) => {
    const { token, ...user } = u.user;
    sessionStorage.setItem(tokenKey, token);
    return user;
  });
}
export function signOut() {
  //TODO: Pending implementation API
  // return apiFetch("/logout", { method: "DELETE" });
}
