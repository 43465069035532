import styled from "@emotion/styled";
import { ClassroomCredentialsForm, PersonalProfileForm } from "components/Form";

function PersonalPage() {
  return (
    <PersonalPageWrapper>
      <ClassroomCredentialsForm />
      <PersonalProfileForm />
    </PersonalPageWrapper>
  );
}

export default PersonalPage;

const PersonalPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 16px;
`;
