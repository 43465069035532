import styled from "@emotion/styled";
import { QUERIES } from "constants";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 64px;
`;

export const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: min(100%, 750px);
  margin-left: auto;
  margin-right: auto;
  @media ${QUERIES.phoneAndSmaller} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
