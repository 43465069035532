import styled from "@emotion/styled";
import { PrimaryButton } from "components/Button";
import { InputGroup } from "components/Inputs";
import { Input, Label } from "components/Inputs/Input";
import InputFile from "components/Inputs/InputFile";
import { Select } from "components/Select";
import { QUERIES } from "constants";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { useAuth } from "context/auth-context";
import { countriesData } from "lib/countries";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { updateUser } from "services/airtable";

const genders = ["Male", "Female", "Non-binary"];

const prefixes = countriesData.map((c) => ({
  value: c.prefix,
  item: c.alpha2Code,
}));

const typesDocuments = ["National ID", "Passport", "Foreigner's card"];

const listCountries = countriesData.map((c) => ({
  value: c.alpha2Code,
  item: c.country,
  region: c.states,
}));

export function PersonalProfileForm() {
  const { user, updateProfile } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      gender: user.gender,
      phone: user.phone,
      prefix: user.prefix,
      lastName: user.lastName,
      preferredName: user.preferredName,
      docNumber: user.docNumber,
      birthdate: user.birthdate,
      nationality: user.nationality,
      country: user.country,
      region: user.region,
      city: user.city,
      zipCode: user.zipCode,
      address: user.address,
      addressRef: user.addressRef,
      avatar: user.avatar[0].url,
    },
  });

  const country = watch("country");

  const regions = listCountries.find((c) => c.value === country).region;

  function onSubmit(data) {
    setIsLoading(true);
    data.avatar = [{ url: data.avatar }];
    updateUser(user.id, data).then((data) => {
      const newUser = {
        firstName: data.firstName,
        gender: data.gender,
        phone: data.phone,
        prefix: data.prefix,
        lastName: data.lastName,
        preferredName: data.preferredName,
        docNumber: data.docNumber,
        birthdate: data.birthdate,
        nationality: data.nationality,
        country: data.country,
        region: data.region,
        city: data.city,
        zipCode: data.zipCode,
        address: data.address,
        addressRef: data.addressRef,
        avatar: data.avatar,
      };
      updateProfile(newUser);
      setIsLoading(false);
    });
  }

  return (
    <PersonalProfileWrapper>
      <Container>
        <>
          <Title>Personal Profile</Title>
          <Label>
            Please keep your information up to date so we can stay in touch 😀.
          </Label>
        </>

        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <Input
            label="First Name"
            register={register("firstName", {
              required: "Este campo es requerido",
            })}
            error={errors.firstName}
          />
          <Input
            label="Last Name"
            register={register("lastName", {
              required: "Este campo es requerido",
            })}
            error={errors.lastName}
          />
          <Input
            label="Preferred name"
            register={register("preferredName", {
              required: "Este campo es requerido",
            })}
            error={errors.preferredName}
          />
          <InputFile
            register={register("avatar")}
            currentImg={watch("avatar")}
            setValue={setValue}
          />
          <Select
            label="Gender"
            options={genders}
            register={register("gender")}
          />
          <InputGroup
            label="Phone"
            options={prefixes}
            registerInput={register("phone")}
            registerSelect={register("prefix")}
          />
          <Select
            label="ID document type"
            options={typesDocuments}
            register={register("docType")}
          />
          <Input
            label="ID document number"
            register={register("docNumber", {
              required: "Este campo es requerido",
            })}
            error={errors.docNumber}
          />
          <Input
            type="date"
            label="Birthdate"
            register={register("birthdate", {
              required: "Este campo es requerido",
            })}
            error={errors.birthdate}
          />
          <Select
            label="Nationality"
            register={register("nationality")}
            options={listCountries}
          />
          <SubTitle>Place of residence</SubTitle>
          <Select
            label="Country"
            register={register("country")}
            options={listCountries}
          />
          <Select
            label="Region / State"
            register={register("region")}
            options={regions}
          />
          <Input
            label="City"
            register={register("city", { required: "Este campo es requerido" })}
            error={errors.city}
          />
          <Input
            label="ZIP / Postal Code"
            register={register("zipCode", {
              required: "Este campo es requerido",
            })}
            error={errors.zipCode}
          />
          <Row cols={2}>
            <Input
              label="Address"
              register={register("address", {
                required: "Este campo es requerido",
              })}
              error={errors.address}
            />
          </Row>
          <Row cols={2}>
            <Input
              label="Reference"
              register={register("addressRef", {
                required: "Este campo es requerido",
              })}
              error={errors.addressRef}
            />
          </Row>
          <Row cols={2} justify="end">
            <PrimaryButton disabled={!isDirty}>
              {isLoading ? "Saving..." : "Save"}
            </PrimaryButton>
          </Row>
        </FormContainer>
      </Container>
    </PersonalProfileWrapper>
  );
}

export const Title = styled.h1`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
  color: var(--color-gray-900);
`;
const SubTitle = styled.h2`
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.medium};
  grid-column: 1 / -1;
`;

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  align-items: end;

  @media ${QUERIES.tabletAndSmaller} {
    grid-template-columns: 1fr;
  }
`;
const PersonalProfileWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-white);
`;

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  grid-column: span ${({ cols }) => cols};
  justify-self: ${({ justify = "unset" }) => justify};

  @media ${QUERIES.tabletAndSmaller} {
    grid-column: 1 / -1;
  }
`;
