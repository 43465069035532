import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { COLORS, QUERIES } from "constants";
import { useState } from "react";
import { Menu, X } from "react-feather";

function NavigationLayout({ aside, children }) {
  const [isOpenModal, setIsOpenModal] = useState(false);

  return (
    <Layout>
      <Modal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
        <Panel>
          <CloseButton onClick={() => setIsOpenModal(false)}>
            <X />
          </CloseButton>
          {aside}
        </Panel>
      </Modal>
      <Aside>{aside}</Aside>
      <MenuButton
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        <Menu />
      </MenuButton>
      {children}
    </Layout>
  );
}

export default NavigationLayout;

const Layout = styled.div`
  display: grid;
  height: 100%;
  background-color: var(--color-gray-50);
  grid-template-columns: 300px 1fr;
  @media ${QUERIES.tabletAndSmaller} {
    grid-template-columns: none;
    grid-template-rows: 40px auto;
  }
`;

const Main = styled.section`
  height: 100%;
  padding: 16px;
  background-color: var(--color-gray-50);

  @media ${QUERIES.tabletAndSmaller} {
    padding-top: 0;
  }

  @media ${QUERIES.phoneAndSmaller} {
    padding-right: 0;
    padding-top: 0;
  }
`;

const Aside = styled.aside`
  background-color: var(--color-white);
  padding: 16px;
  @media ${QUERIES.tabletAndSmaller} {
    display: none;
  }
`;

const Modal = styled(Dialog)`
  --header-height: 64px;
  position: absolute;
  top: var(--header-height);
  bottom: 0;
  left: 0;
  right: 0;
  background-color: hsla(${COLORS.gray[900]}, 0.6);
`;

const Panel = styled(Dialog.Panel)`
  background-color: var(--color-white);
  position: fixed;
  width: 300px;
  padding: 16px;
  top: var(--header-height);
  bottom: 0;
`;

const CloseButton = styled.button`
  display: block;
  z-index: 1;
  line-height: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  position: absolute;
  right: 8px;
  top: 8px;
`;

const MenuButton = styled.button`
  display: none;
  border: none;
  padding: 8px;
  margin-left: 16px;
  background: none;
  line-height: 0;
  width: min-content;
  cursor: pointer;
  @media ${QUERIES.tabletAndSmaller} {
    display: block;
  }
`;
