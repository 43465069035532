import styled from "@emotion/styled";
import { SIZES, WEIGHTS } from "constants";

function Footer() {
  return (
    <Wrapper>
      <p>2019 - present Codeable</p>
      <p>This app was created entirely by our graduates 🚀</p>
    </Wrapper>
  );
}

export default Footer;

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 32px;
  text-align: center;
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
`;
