import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OutlineButton } from "components/Button";
import ContentWrapper from "components/ContentWrapper";
import GithubButton from "components/GitubButton/GithubButton";
import MDXContent from "components/MDXContent";
import { useAuth } from "context/auth-context";
import {
  useLessonCohort,
  useModuleUnitsQuery,
  useStudentProgramQuery,
} from "hooks/queries";
import { useParams } from "react-router-dom";
import { completeLesson } from "services/airtable";
import CompleteButton from "./CompleteButton";
import Exercise from "./Exercise";
import * as S from "./styled";

export default function LessonPage() {
  const { user } = useAuth();
  const params = useParams();
  const queryClient = useQueryClient();
  const lessonCohortQuery = useLessonCohort(params.cohort, params.module);

  const student = user.student.find((s) => s.cohortSlug[0] === params.cohort);

  const mutation = useMutation({
    mutationFn: ({ studentProgramId, lessonId }) =>
      completeLesson(studentProgramId, lessonId),
    onMutate: async ({ studentProgramId, lessonId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["student", student.id, "program", params.program],
      });

      // Snapshot the previous value
      const previousStudentProgram = queryClient.getQueryData([
        "student",
        student.id,
        "program",
        params.program,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["student", student.id, "program", params.program],
        (prev) => ({
          ...prev,
          completedLessons: prev.completedLessons
            ? [...prev.completedLessons, lessonId]
            : [lessonId],
        })
      );

      // Return a context object with the snapshotted value
      return { previousStudentProgram };
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["student", student.id, "program", params.program],
        });
      }, 300);
    },
  });

  const { data: studentProgram } = useStudentProgramQuery(
    student.id,
    params.program
  );

  const { data: units } = useModuleUnitsQuery(params.module);
  const unit = units.find((u) => u.slug === params.unit);

  const currentLesson = unit.lessons.find((l) => l.slug === params.lesson);
  const currentLessonCohort = lessonCohortQuery.data?.find(
    (item) => item.lesson[0] === currentLesson.id
  );

  const isCompleted = studentProgram.completedLessons?.includes(
    currentLesson.id
  );
  const path = [params.program, params.module, params.lesson].join("/");

  const currentLessons = JSON.parse(
    localStorage.getItem("classroom-current-lessons")
  );

  localStorage.setItem(
    "classroom-current-lessons",
    JSON.stringify({ ...currentLessons, [params.program]: currentLesson })
  );

  function handleCompleteLesson() {
    mutation.mutate({
      studentProgramId: studentProgram.id,
      lessonId: currentLesson.id,
    });
  }

  if (currentLesson.type === "Exercises") {
    return (
      <Exercise
        currentLesson={currentLesson}
        handleCompleteLesson={handleCompleteLesson}
        isCompleted={isCompleted}
      />
    );
  }

  return (
    <S.Wrapper>
      <ContentWrapper>
        <MDXContent path={path} />
      </ContentWrapper>
      <S.Footer>
        <OutlineButton>Submit Feedback</OutlineButton>
        {currentLessonCohort?.assignmentUrl && (
          <GithubButton
            href={currentLessonCohort.assignmentUrl}
            target="_blank"
          >
            Open Repo
          </GithubButton>
        )}
        <CompleteButton
          mutation={() =>
            mutation.mutate({
              studentProgramId: studentProgram.id,
              lessonId: currentLesson.id,
            })
          }
          isCompleted={isCompleted}
        />
      </S.Footer>
    </S.Wrapper>
  );
}
