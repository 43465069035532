import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getEventParticipation,
  getLessonsByCohort,
  getModulesByProgramSlug,
  getProgramBySlug,
  getStudentProgram,
  getUnitsByModuleSlug,
} from "services/airtable";

export function useProgramQuery(programSlug) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["programs", programSlug],
    queryFn: () => getProgramBySlug(programSlug),
    staleTime: 30 * 60 * 1000,
    onSuccess: (program) => {
      queryClient.setQueryData(["programs"], (prev) =>
        prev ? [...prev, program] : [program]
      );
    },
  });
}

export function useModulesQuery(programSlug) {
  return useQuery({
    queryKey: ["programs", programSlug, "modules"],
    queryFn: () => getModulesByProgramSlug(programSlug),
    staleTime: 30 * 60 * 1000,
  });
}

export function useModuleUnitsQuery(moduleSlug) {
  return useQuery({
    queryKey: ["modules", moduleSlug, "units"],
    queryFn: () => getUnitsByModuleSlug(moduleSlug),
    staleTime: 30 * 60 * 1000,
  });
}

export function useStudentProgramQuery(studentId, programSlug) {
  return useQuery({
    queryKey: ["student", studentId, "program", programSlug],
    queryFn: () => getStudentProgram(studentId, programSlug),
  });
}

export function useEventParticipationQuery(studentId, moduleSlug) {
  return useQuery({
    queryKey: ["modules", moduleSlug, "events"],
    queryFn: () => getEventParticipation(studentId, moduleSlug),
    staleTime: 30 * 60 * 1000,
  });
}

export function useLessonCohort(cohortSlug, moduleSlug) {
  return useQuery({
    queryKey: ["cohort", cohortSlug, "module", moduleSlug, "lessons"],
    queryFn: () => getLessonsByCohort(cohortSlug, moduleSlug),
  });
}
