import Airtable from "airtable";

Airtable.configure({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY });
const classroomBaseId = process.env.REACT_APP_BASE_ID;

const base = Airtable.base(classroomBaseId);

function buildRecord(record) {
  return {
    id: record.id,
    ...record.fields,
  };
}

function sort(records, sortKey = "sort") {
  return records.sort((a, b) => a[sortKey] - b[sortKey]);
}

export const tables = {
  user: "tbl5ZRmNHaMG7HC3x",
  cohort: "tblZviphGLLWjG8xG",
  student: "tbluig9lzMXzFPAAX",
  program: "tblKi08NYYNcExOuf",
  module: "tblMxQD8Q0D4hhOnR",
  unit: "tblTznYz0t0KHBcbV",
  lesson: "tbl4jThLZTrAU2DyB",
  exercise: "tbldRxLhSyvw6d397",
  studentProgram: "tbl2L2zzVV0dbbxF4",
  lessonCohort: "tblqcmboVbw6gBlSb",
  lessonFeedback: "tbl1ezNSstYGiHttW",
  exerciseFeedback: "tblEDWKewsqMVUWtC",
  event: "tblLPG33qipTY5wkp",
  eventParticipation: "tblE7xs6NBeJfR4Db",
  projectFeedback: "tbl1e7F8nbNLrwRt4",
  projectGrade: "tblY6W2OxzzgPixZD",
  individualGrade: "tbloYJFbk3ZiHCSDu",
  resource: "tbl8zJ6FOMNJ2lHQr",
  skill: "tbliHohMSoU0Crh1r",
  userSkill: "tblkRk1nZ5db23oZs",
  education: "tbl5lvx7fZ5yH7C2h",
  experience: "tblWgSkmTIYKsIs5x",
};

export function getTable(table, selectOptions = {}) {
  return new Promise((resolve, reject) => {
    const data = [];
    base(table)
      .select(selectOptions)
      .eachPage(
        (records, fetchNextPage) => {
          records.forEach(function (record) {
            data.push(buildRecord(record));
          });

          fetchNextPage();
        },
        (error) => {
          if (error) reject(error);
          resolve(data);
        }
      );
  });
}

export function getRecord(table, id) {
  return new Promise((resolve, reject) => {
    base(table).find(id, (error, record) => {
      if (error) reject(error);
      resolve(buildRecord(record));
    });
  });
}

export function createRecords(table, ...records) {
  return new Promise((resolve, reject) => {
    base(table).create(records.flat(), (error, records) => {
      if (error) reject(error);
      resolve(records.map(buildRecord));
    });
  });
}

export function updateRecord(table, recordId, data) {
  return new Promise((resolve, reject) => {
    base(table).update(recordId, data, (error, record) => {
      if (error) reject(error);
      resolve(buildRecord(record));
    });
  });
}

export async function getUserByEmail(email) {
  const users = await getTable(tables.user, {
    filterByFormula: `{email} = '${email}'`,
    maxRecords: 1,
  });

  const user = users[0];

  user.student = await getTable(tables.student, {
    filterByFormula: `{userId} = '${user.id}'`,
  });

  const activeCohorts = [];
  for (let student of user.student.filter((s) => s.status === "Active")) {
    const data = await getTable(tables.cohort, {
      filterByFormula: `{id} = '${student.cohort[0]}'`,
    });
    activeCohorts.push(data[0]);
  }

  user.activeCohorts = activeCohorts;

  return user;
}

export function getProgramsByCohort(cohortId) {
  return getTable(tables.program, {
    sort: [{ field: "sort", direction: "asc" }],
  }).then((programs) => {
    return programs.filter((program) => program.cohorts.includes(cohortId));
  });
}

export function getProgramBySlug(programSlug) {
  return getTable(tables.program, {
    filterByFormula: `slug = "${programSlug}"`,
  }).then((cohorts) => cohorts[0]);
}

export function getCohort(cohortId) {
  return getRecord(tables.cohort, cohortId);
}

export function getCohortBySlug(cohortSlug) {
  return getTable(tables.cohort, {
    filterByFormula: `slug = "${cohortSlug}"`,
  }).then((cohorts) => cohorts[0]);
}

export function getStudent(user, cohort) {
  return getTable(tables.student, {
    filterByFormula: `AND(user = "${user}", cohort = "${cohort}")`,
  }).then((data) => data[0]);
}

export function getStudentProgram(studentId, programSlug) {
  return getTable(tables.studentProgram, {
    filterByFormula: `AND({studentId} = "${studentId}", {programSlug} = "${programSlug}")`,
  }).then((data) => data[0] || {});
}

export function getModulesByProgram(programId) {
  return getTable(tables.module).then((modules) => {
    return sort(modules.filter((module) => module.program.includes(programId)));
  });
}

export function getModulesByProgramSlug(programSlug) {
  return getTable(tables.module, {
    filterByFormula: `programSlug = "${programSlug}"`,
    sort: [{ field: "sort", direction: "asc" }],
  });
}

export function getUnitsByModule(moduleId) {
  return getTable(tables.unit).then((units) => {
    return sort(units.filter((unit) => unit.module.includes(moduleId)));
  });
}

export async function getUnitsByModuleSlug(moduleSlug) {
  const units = await getTable(tables.unit, {
    filterByFormula: `moduleSlug = "${moduleSlug}"`,
    sort: [{ field: "sort", direction: "asc" }],
  });
  for (const unit of units) {
    unit.lessons = await getLessonsByUnitModuleSlugs(unit.slug, moduleSlug);
    unit.lessons.forEach(async (lesson) => {
      if (lesson.type === "Exercises") {
        const exercises = await getExercises(lesson.id);
        lesson.exercises = sort(exercises);
      }
    });
  }

  return units;
}

export function getLessonsByUnit(unitId) {
  return getTable(tables.lesson).then((lessons) => {
    return sort(lessons.filter((lesson) => lesson.unit.includes(unitId)));
  });
}

export function getLessonsByUnitModuleSlugs(unitSlug, moduleSlug) {
  return getTable(tables.lesson, {
    filterByFormula: `AND(unitSlug = "${unitSlug}", moduleSlug = "${moduleSlug}")`,
    sort: [{ field: "sort", direction: "asc" }],
  }).then(sort);
}

export function getModuleBySlug(moduleSlug) {
  return getTable(tables.module, {
    filterByFormula: `slug = "${moduleSlug}"`,
  }).then((modules) => modules[0]);
}

export function getStudentPrograms(studentPK) {
  return getTable(tables.studentProgram, {
    filterByFormula: `studentPK = "${studentPK}"`,
  });
}

export function completeLesson(studentProgramId, lessonId) {
  return getRecord(tables.studentProgram, studentProgramId).then((record) => {
    return base(tables.studentProgram).update(
      record.id,
      {
        completedLessons: record.completedLessons
          ? [...record.completedLessons, lessonId]
          : [lessonId],
      },
      (err, record) => {
        if (err) {
          console.error(err);
          return;
        }
        return record;
      }
    );
  });
}

export function completeExercise(studentProgramId, exerciseId) {
  return getRecord(tables.studentProgram, studentProgramId).then((record) => {
    return base(tables.studentProgram).update(
      record.id,
      {
        completedExercises: record.completedExercises
          ? [...record.completedExercises, exerciseId]
          : [exerciseId],
      },
      (err, record) => {
        if (err) {
          console.error(err);
          return;
        }
        return record;
      }
    );
  });
}

export async function updateStudentPrograms(students) {
  for (const student of students) {
    const studentPrograms = await getTable(tables.studentProgram, {
      filterByFormula: `studentId = "${student.id}"`,
    });
    const newPrograms = student.cohortPrograms.filter(
      (program) => !studentPrograms.find((sp) => sp.program[0] === program)
    );

    if (newPrograms.length > 0) {
      const data = newPrograms.map((newProgram) => ({
        fields: { student: [student.id], program: [newProgram] },
      }));
      await createRecords(tables.studentProgram, data);
    }
  }
}

export function getEventParticipation(studentId, moduleSlug) {
  return getTable(tables.eventParticipation, {
    sort: [{ field: "date", direction: "asc" }],
    filterByFormula: `AND({studentId} = "${studentId}", {moduleSlug} = "${moduleSlug}")`,
  });
}

export function getExercises(lessonId) {
  return getTable(tables.exercise, {
    filterByFormula: `{lessonId} = "${lessonId}"`,
  });
}

export function getLessonsByCohort(cohortSlug, moduleSlug) {
  return getTable(tables.lessonCohort, {
    filterByFormula: `AND({cohortSlug} = "${cohortSlug}", {moduleSlug} = "${moduleSlug}")`,
  });
}

export function updateUser(userId, data) {
  return updateRecord(tables.user, userId, data);
}
