import styled from "@emotion/styled";
import { Link, useParams } from "react-router-dom";
import check from "assets/icons/check.svg";
import { COLORS, SIZES, WEIGHTS } from "constants";
import { useAuth } from "context/auth-context";
import { useLessonCohort, useStudentProgramQuery } from "hooks/queries";
import Spinner from "components/Spinner";

function LessonList({ lessons = [] }) {
  const { user } = useAuth();
  const params = useParams();
  const student = user.student.find((s) => s.cohortSlug[0] === params.cohort);

  const studentProgramQuery = useStudentProgramQuery(
    student.id,
    params.program
  );

  const lessonCohortQuery = useLessonCohort(params.cohort, params.module);

  if (studentProgramQuery.isLoading) return <Spinner />;

  const completedLessons = studentProgramQuery.data.completedLessons || [];

  return (
    lessonCohortQuery.isSuccess && (
      <List>
        {lessons.map((lesson) => {
          if (lesson.parent) return null;
          const lessonCohort = lessonCohortQuery.data.find(
            (item) => item.lessonId[0] === lesson.id
          );
          let disabled;
          if (lessonCohort)
            disabled = Date.parse(lessonCohort.openingDate) > Date.now();
          else disabled = true;
          return (
            <ItemList key={lesson.id}>
              <StyledLink
                to={disabled ? "" : `/${params.cohort}/${lesson.path}`}
                disabled={disabled}
              >
                <Checked completed={completedLessons.includes(lesson.id)} />
                {lesson.title}
              </StyledLink>
              {lesson.children && (
                <ListChild>
                  {lesson.children.map((childId) => {
                    const childLesson = lessons.find((l) => l.id === childId);
                    const lessonCohort = lessonCohortQuery.data.find(
                      (item) => item.lessonId[0] === childLesson.id
                    );
                    let disabled;
                    if (lessonCohort)
                      disabled =
                        Date.parse(lessonCohort.openingDate) > Date.now();
                    else disabled = true;
                    return (
                      <li key={childId}>
                        <LinkChild
                          to={
                            disabled
                              ? ""
                              : `/${params.cohort}/${childLesson.path}`
                          }
                          disabled={disabled}
                        >
                          <Checked
                            completed={completedLessons.includes(childId)}
                          />
                          {childLesson.title}
                        </LinkChild>
                      </li>
                    );
                  })}
                </ListChild>
              )}
            </ItemList>
          );
        })}
      </List>
    )
  );
}
export default LessonList;

const List = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;

const ItemList = styled.li`
  /* margin-bottom: 1rem; */
`;

const StyledLink = styled(Link)`
  display: flex;
  text-decoration: none;
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.medium};
  align-items: center;
  padding: 16px 8px;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:any-link,
  &:-webkit-any-link {
    color: ${({ disabled }) =>
      disabled ? `hsla(${COLORS.text}, 0.5)` : "inherit"};
  }

  &:hover {
    background-color: hsla(${COLORS.tertiary}, 0.2);
    border-radius: 8px;
  }
  &:active {
    background-color: hsla(${COLORS.white});
    color: hsla(${COLORS.tertiary});
  }
`;
const lessonCompleted = (completed) => {
  if (completed) {
    return `
    background-color: hsla(${COLORS.primary});
    background-image: url(${check});
    background-repeat: no-repeat;
    background-position: center;
    `;
  }
  return `background: hsla(${COLORS.secondary})`;
};

const Checked = styled.div`
  display: inline-block;
  border-radius: 9px;
  width: 18px;
  height: 18px;
  margin-right: 0.5rem;
  ${(props) => lessonCompleted(props.completed)}
`;

const ListChild = styled.ol`
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`;
const LinkChild = styled(StyledLink)`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.medium};
`;
