import styled from "@emotion/styled";
import { QUERIES, WEIGHTS } from "constants";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import { NavLink } from "react-router-dom";

const defaultNavigation = [
  { label: "Modules", to: "modules" },
  { label: "Metrics", to: "metrics" },
  // { label: "Resources", to: "resources" },
];

function ProgramNav({ navigation = defaultNavigation }) {
  return (
    <MaxWidthWrapper>
      <Container>
        {navigation.map((nav) => (
          <NavLink to={nav.to} key={nav.label}>
            {nav.label}
          </NavLink>
        ))}
      </Container>
    </MaxWidthWrapper>
  );
}

export default ProgramNav;

const Container = styled.nav`
  display: flex;
  gap: 4px;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border: 2px solid var(--color-gray-50);
    min-width: 120px;
    border-radius: 12px 12px 0 0;
    text-decoration: none;

    &:hover {
      background-color: var(--color-gray-50);
    }

    @media (${QUERIES.phoneAndSmaller}) {
      min-width: min-content;
    }

    &.active {
      background-color: var(--color-gray-50);
      color: var(--color-primary);
      font-weight: ${WEIGHTS.medium};
    }
  }
`;
