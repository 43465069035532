import { WEIGHTS, SIZES, COLORS, QUERIES } from "constants";

import styled from "@emotion/styled";

import { ReactComponent as Success } from "assets/icons/success.svg";
import { ReactComponent as Info } from "assets/icons/info.svg";
import { ReactComponent as Warning } from "assets/icons/warning.svg";
import { ReactComponent as Danger } from "assets/icons/danger.svg";
import VideoPlayer from "components/VideoPlayer";

const H1 = styled.h1`
  font-size: ${SIZES["5xl"]};
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 40px;

  & ~ * {
    margin-top: 0 !important;
  }
`;

const H2 = styled.h2`
  font-size: ${SIZES["3xl"]};
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 24px;
  margin-top: 48px;
  color: var(--color-primary);

  & ~ * {
    margin-top: 0 !important;
  }
`;

const H3 = styled.h3`
  font-size: ${SIZES["2xl"]};
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 24px;
  margin-top: 32px;
  color: var(--color-tertiary);

  & ~ * {
    margin-top: 0 !important;
  }
`;

const P = styled.p`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.light};
  margin-bottom: 24px;
  line-height: 1.8;
`;

const UL = styled.ul`
  padding-left: 2em;
  & li::marker {
    color: var(--color-primary);
    font-weight: ${WEIGHTS.bold};
    font-size: 1.5em;
  }
  /* list-style-position: inside; */
  /* list-style: none;

  & > li::before {
    content: "";
    display: inline-block;
    width: var(--size);
    height: var(--size);
    background-color: var(--color-primary);
    border-radius: calc(var(--size) * 0.5);
    margin-right: var(--size);
    margin-left: 4px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: var(--size);
  } */
`;

const OL = styled.ol`
  padding-left: 2em;
  & li::marker {
    color: var(--color-primary);
    font-weight: ${WEIGHTS.bold};
    font-size: 1.1em;
  }
  /* padding: 0;
  list-style: none;
  counter-reset: steps;

  & > li::before {
    counter-increment: steps;
    content: counter(steps) ".";
    margin-left: 4px;
    color: var(--color-primary);
    font-weight: ${WEIGHTS.bold};
    display: inline-block;
    width: calc(var(--size) * 2);
  } */
`;

const LI = styled.li`
  /* --size: ${12 / 16}rem; */
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: ${SIZES.lg};
  line-height: 1.8;

  /* & > ul,
  & > ol {
    padding-left: var(--size);
    margin-top: var(--size);
  } */
`;

const Aside = ({ title, type = "info", children }) => {
  const icons = {
    info: Info,
    success: Success,
    warning: Warning,
    danger: Danger,
  };

  const Icon = icons[type];

  return (
    <AsideWrapper type={type}>
      <AsideIcon>
        <Icon />
      </AsideIcon>
      <AsideTitle>{title}</AsideTitle>
      {children}
    </AsideWrapper>
  );
};

const AsideWrapper = styled.aside`
  padding: 32px;
  background-color: ${(p) => `hsla(${COLORS[p.type]}, 0.15)`};
  border-radius: 8px;
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 64px;
  position: relative;

  & > :last-child {
    margin-bottom: 0px;
  }

  @media ${QUERIES.tabletAndSmaller} {
    padding: 32px 16px;
    border-radius: 0;
    margin-left: -16px;
    margin-right: -16px;
  }
`;

const AsideTitle = styled.strong`
  display: block;
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 1em;
`;

const AsideIcon = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-33.3%, -33.3%);

  & > svg {
    display: block;
  }

  @media ${QUERIES.tabletAndSmaller} {
    display: none;
  }
`;

const HR = styled.hr`
  width: 100%;
`;

const A = styled.a`
  color: var(--color-secondary);
  font-weight: ${WEIGHTS.bold};
  transition: color 150ms;

  &:hover {
    color: var(--color-tertiary);
  }
`;

const PDF = ({ src }) => {
  return (
    <iframe
      title={src}
      style={{ width: "100%", maxWidth: "100%", height: "60vh" }}
      src={src}
    />
  );
};

export const components = {
  h1: H1,
  h2: H2,
  h3: H3,
  p: P,
  a: A,
  ul: UL,
  ol: OL,
  li: LI,
  hr: HR,
  Aside,
  VideoPlayer,
  PDF,
};
