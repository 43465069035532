import { Route, Routes } from "react-router-dom";
import { useAuth } from "context/auth-context";

import Dashboard from "pages/Dashboard";
import MainLayout from "pages/Layouts/MainLayout";
import ProgramPage from "pages/Program/ProgramPage";
import Spinner from "components/Spinner";
import ModulesPage from "pages/Program/ModulesPage";
import Module from "pages/Program/Module";
import LessonLayout from "pages/Layouts/LessonLayout";
import ProfileLayout from "pages/Layouts/ProfileLayout";
import PersonalPage from "pages/Profile/PersonalPage";
import MetricsPage from "pages/Program/MetricsPage";
import LessonPage from "pages/LessonPage";
import { useProgramQuery } from "hooks/queries";
import ModuleMetric from "pages/Program/ModuleMetric";

function AuthenticatedApp() {
  const { user } = useAuth();

  const programsSlugs = [
    ...new Set(user.activeCohorts.map((ac) => ac.programsSlugs).flat()),
  ];

  const programsQuery = programsSlugs.map(useProgramQuery);

  const programsSuccess = programsQuery.every((q) => q.isSuccess);

  if (!programsSuccess) return <Spinner />;

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="profile" element={<ProfileLayout />}>
          <Route path="personal" element={<PersonalPage />} />
          <Route path="developer" element={<h1>Developer Profile</h1>} />
          <Route path="education" element={<h1>Education</h1>} />
          <Route path="experience" element={<h1>Work Experience</h1>} />
        </Route>
        <Route path=":cohort/:program" element={<ProgramPage />}>
          <Route path="modules" element={<ModulesPage />}>
            <Route path=":module" element={<Module />} />
          </Route>
          <Route path="metrics" element={<MetricsPage />}>
            <Route path=":module" element={<ModuleMetric />} />
          </Route>
          <Route path="resources" element={<div>Resources Page</div>} />
        </Route>
        <Route
          path=":cohort/:program/:module/:unit/:lesson"
          element={<LessonLayout />}
        >
          <Route index element={<LessonPage />} />
        </Route>
      </Route>
    </Routes>
  );
}
export default AuthenticatedApp;
