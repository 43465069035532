import styled from "@emotion/styled";
import { SIZES, WEIGHTS, QUERIES } from "constants";

import MaxWidthWrapper from "components/MaxWidthWrapper";
import programCardImage from "assets/images/programCardImage.svg";
import englishImg from "assets/images/english.svg";
import employabilityImg from "assets/images/employability.svg";
import defaultImg from "assets/images/default.svg";

const programImages = {
  "full-stack-web-dev": programCardImage,
  english: englishImg,
  employability: employabilityImg,
  default: defaultImg,
};

function ProgramInfo({ program }) {
  const programImage = programImages[program.slug] || programImages["default"];
  return (
    <MaxWidthWrapper>
      <GreetingWrapper>
        <Content>
          <Title>{program.title}</Title>
          <Description>{program.description}</Description>
        </Content>

        <CardImage src={programImage} alt="programCardImage" />
      </GreetingWrapper>
    </MaxWidthWrapper>
  );
}

const GreetingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const Content = styled.div`
  max-width: 600px;
  flex-shrink: 1;

  @media (${QUERIES.tabletAndSmaller}) {
    max-width: none;
  }
`;

const Title = styled.h2`
  font-size: ${SIZES["4xl"]};
  font-weight: ${WEIGHTS.bold};
  margin-bottom: 8px;
`;

const Description = styled.p`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
`;

const CardImage = styled.img`
  width: 160px;
  flex-shrink: 0;
  @media ${QUERIES.tabletAndSmaller} {
    display: none;
  }
`;

export default ProgramInfo;
