import styled from "@emotion/styled";
import { QUERIES } from "constants";
import ReactPlayer from "react-player";

const VideoPlayer = (props) => {
  return (
    <PlayerWrapper>
      <StyledReactPlayer
        controls={true}
        width="100%"
        height="100%"
        config={{
          youtube: {
            playerVars: {
              rel: 0,
              origin: "https://www.youtube.com",
              enablejsapi: 0,
            },
            embedOptions: { origin: "https://www.youtube.com", enablejsapi: 0 },
          },
        }}
        {...props}
      />
    </PlayerWrapper>
  );
};

export default VideoPlayer;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const PlayerWrapper = styled.div`
  --negative-margin: -32px;
  --aspect-ratio: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  --extra-padding: calc(var(--negative-margin) * -2 * 0.5625);
  position: relative;
  margin-left: var(--negative-margin);
  margin-right: var(--negative-margin);
  margin-bottom: 64px;
  padding-top: calc(var(--aspect-ratio) + var(--extra-padding));

  @media ${QUERIES.tabletAndSmaller} {
    --negative-margin: -16px;
  }
`;
