export function Options({ options }) {
  return (
    <>
      {options.map((option) =>
        <option key={option.value || option} value={option.value || option}>{option.item || option}</option>
      )}
    </>
  )
}


//  const prefixes = [{ nationality: 'MEX', prefix: '+52' }, { nationality: 'PE', prefix: '+51' }]
//   const countries = [{ value: 'PE', item: 'Perú' }, { value: 'MEX', item: 'México' }]
