import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";

const { getRandomElement } = require("lib/helpers");

const fetchQuote = () => {
  return fetch("https://type.fit/api/quotes")
    .then((res) => res.json())
    .then((quotes) => getRandomElement(quotes));
};

export default function Quote() {
  const { data: quote } = useQuery({
    queryKey: ["quote"],
    queryFn: fetchQuote,
    placeholderData: [{ text: "A quote is on the way", author: "Quoter" }],
    staleTime: 1000 * 60 * 30,
  });

  return (
    <Wrapper>
      "{quote.text}" -{quote.author || "Unknown"}
    </Wrapper>
  );
}

const Wrapper = styled.p`
  min-height: calc(2em * 1.5);
`;
