import styled from "@emotion/styled";
import { QUERIES } from "constants";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { NavLink } from "react-router-dom";

const defaultNavigation = [
  { label: "Personal Profile", to: "personal" },
  // { label: "Developer Profile", to: "developer" },
  // { label: "Education", to: "education" },
  // { label: "Experience", to: "experience" },
];

function ProfileNav({ navigation = defaultNavigation }) {
  return (
    <Container>
      {navigation.map((nav) => (
        <NavLink to={nav.to} key={nav.label}>
          {nav.label}
        </NavLink>
      ))}
    </Container>
  );
}

export default ProfileNav;

const Container = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  list-style: none;
  padding-left: 0;
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};

  & > a {
    text-decoration: none;
    padding: 8px 16px;
    border: 1px solid transparent;
    &.active,
    &:hover {
      border: 1px solid var(--color-tertiary);
      border-radius: 8px;
      color: var(--color-tertiary);
    }
  }

  @media ${QUERIES.tabletAndSmaller} {
    padding-top: 16px;
  }
`;
