import styled from "@emotion/styled";
import { Select } from "components/Select";
import { Input } from "./Input";

export function InputGroup({ label, registerInput, registerSelect, options }) {

  return (
    <InputGroupWrapper>
      <Select
        label={label}
        register={registerSelect}
      >
        {options.map((option) =>
          <option key={option.value} value={option.value}>{`${option.item}(${option.value})`}</option>
        )}
      </Select>
      <Input
        register={registerInput}
      />
    </InputGroupWrapper>

  )
}

const InputGroupWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: flex-end;
`

