import styled from "@emotion/styled";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { ReactComponent as Question } from "assets/icons/question.svg";
import { ReactComponent as Comment } from "assets/icons/comment.svg";
import { ReactComponent as Share } from "assets/icons/share.svg";
import { QUERIES } from "constants";
import { ChevronDown } from "react-feather";
import { useState } from "react";

const BADGE_ICONS = {
  "Ask questions": Question,
  "Answer questions": Comment,
  "Share insights": Share,
};

const CIRCLE_COLORS = {
  Present: "var(--color-success)",
  Absent: "var(--color-danger)",
  Excused: "var(--color-gray-300)",
  Late: "var(--color-warning)",
};

function calcPercentage(list, key, value) {
  if (list.length === 0) return "0%";

  const subtotal = list.filter((item) => item[key] === value).length;
  return `${Math.round((subtotal / list.length) * 100)}%`;
}

function countBadges(list, badgeName) {
  return list.filter((item) => item.badges?.includes(badgeName)).length;
}

function formatDate(dateString) {
  const date = new Date(Date.parse(dateString));
  return new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  }).format(date);
}

export default function InvolvementCard({ events }) {
  const [isOpen, setIsOpen] = useState(false);

  const presentPercantege = calcPercentage(events, "attendance", "Present");
  const latePercantege = calcPercentage(events, "attendance", "Late");
  const absentPercantege = calcPercentage(events, "attendance", "Absent");
  const excusedPercantege = calcPercentage(events, "attendance", "Excused");

  return (
    <Wrapper>
      <HeaderWrapper>
        <AttendanceWrapper>
          <SmallHeading>Attendance</SmallHeading>
          <AttendanceItems>
            <Item>
              <Circle color="var(--color-success)" />
              Present ({presentPercantege})
            </Item>
            <Item>
              <Circle color="var(--color-warning)" />
              Late ({latePercantege})
            </Item>
            <Item>
              <Circle color="var(--color-danger)" />
              Absent ({absentPercantege})
            </Item>
            <Item>
              <Circle color="var(--color-gray-300)" />
              Excused ({excusedPercantege})
            </Item>
          </AttendanceItems>
        </AttendanceWrapper>
        <BadgesWrapper>
          <SmallHeading>Badges</SmallHeading>
          <BadgesItems>
            <Item>
              <Question />
              Ask questions ({countBadges(events, "Ask questions")})
            </Item>
            <Item>
              <Comment />
              Answer questions ({countBadges(events, "Answer questions")})
            </Item>
            <Item>
              <Share />
              Share insights ({countBadges(events, "Share insights")})
            </Item>
          </BadgesItems>
        </BadgesWrapper>
        <ExpandContainer onClick={() => setIsOpen(!isOpen)}>
          <ChevronDown />
        </ExpandContainer>
      </HeaderWrapper>
      {isOpen && (
        <div>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Event</th>
                <th>Attendance</th>
                <th>Badges</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => {
                return (
                  <tr key={event.id}>
                    <td>{formatDate(event.date[0])}</td>
                    <td>{event.title[0]}</td>
                    <td>
                      <Item>
                        <Circle color={CIRCLE_COLORS[event.attendance]} />
                        {event.attendance}
                      </Item>
                    </td>
                    <td>
                      <Badges>
                        {event.badges?.map((badge, index) => {
                          const Icon = BADGE_ICONS[badge];
                          return <Icon key={index} />;
                        })}
                      </Badges>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  border: 1px solid var(--color-gray-200);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media ${QUERIES.phoneAndSmaller} {
    flex-direction: column;
  }
`;

const AttendanceWrapper = styled.div`
  flex: 3 1 300px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media ${QUERIES.phoneAndSmaller} {
    flex: 1;
  }
`;

const BadgesWrapper = styled.div`
  flex: 5 60 500px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media ${QUERIES.phoneAndSmaller} {
    flex: 1;
  }
`;

const SmallHeading = styled.p`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
  color: var(--color-text);
`;

const AttendanceItems = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
  gap: 8px;
`;

const BadgesItems = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

const Item = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.medium};
  color: var(--color-text);

  & > svg {
    flex-shrink: 0;
  }
`;

const Circle = styled.span`
  display: inline-block;
  height: 14px;
  width: 14px;
  background-color: ${(props) => props.color || "var(--color-success)"};
  border-radius: 1000px;
`;

const ExpandContainer = styled.div`
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  align-self: center;

  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  border-radius: 1000px;

  transition: background-color 250ms;

  &:hover {
    background-color: var(--color-gray-50);
  }
`;

const Table = styled.table`
  width: 100%;
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.medium};
  color: var(--color-text);
  border-collapse: collapse;

  & th {
    font-size: ${SIZES.sm};
    font-weight: ${WEIGHTS.light};
    color: var(--color-text);
    text-align: left;
    padding: 8px;
  }

  & td {
    padding: 8px;
    border-top: 1px solid var(--color-gray-100);
  }

  & tr > *:nth-child(1) {
    width: 160px;
    min-width: 160px;
  }

  & tr > *:nth-child(2) {
    width: 200px;
    min-width: 160px;
  }

  & tr > *:nth-child(3) {
    width: 120px;
    min-width: 120px;
  }

  @media ${QUERIES.tabletAndSmaller} {
    & tr > * {
      min-width: auto !important;
    }
  }
`;

const Badges = styled.div`
  display: flex;
  gap: 16px;

  & > * {
    flex-shrink: 0;
  }

  @media ${QUERIES.phoneAndSmaller} {
    flex-wrap: wrap;
  }
`;
