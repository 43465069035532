import styled from "@emotion/styled";
import { COLORS } from "constants";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";

export const Button = styled.button`
  padding: ${12 / 16}rem ${16 / 16}rem;
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  /* border: 2px solid transparent; */
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: var(--color-primary);
  color: var(--color-white);
  &:hover {
    background-color: hsla(${COLORS.primary}, 0.9);
  }
`;

export const OutlineButton = styled(Button)`
  background-color: none;
  color: var(--color-text);
  border: 2px solid var(--color-primary);

  &:hover {
    background-color: hsla(${COLORS.primary}, 0.1);
  }
`;
