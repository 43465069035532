import styled from "@emotion/styled";
import { URL_CLOUDINARY } from "config";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { useEffect, useRef } from "react";

function InputFile({ register, currentImg, setValue }) {
  const hiddenFileInput = useRef(null);
  const { ref, ...rest } = register;

  function handleClick() {
    hiddenFileInput.current.click();
  }

  function handleRef(e) {
    ref(e); // register(name)
    hiddenFileInput.current = e;
  }

  useEffect(() => {
    if (typeof currentImg === "object") {
      const data = new FormData();
      data.append("file", currentImg[0]);
      data.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET);
      fetch(URL_CLOUDINARY, {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          setValue("avatar", data.url);
        })
        .catch((err) => console.log(err));
    }
  }, [currentImg, setValue]);

  return (
    <InputFileWrapper>
      <Avatar src={currentImg} />
      <Button onClick={handleClick}>Change</Button>
      <StyledInput type="file" {...rest} ref={handleRef} />
    </InputFileWrapper>
  );
}

export default InputFile;

const InputFileWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const StyledInput = styled.input`
  display: none;
`;
const Button = styled.button`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.medium};
  background: transparent;
  border: 1px solid var(--color-primary);
  padding: 9px 13px;
  border-radius: 8px;
  cursor: pointer;
`;
const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
`;
