import MaxWidthWrapper from "components/MaxWidthWrapper";
import { useParams } from "react-router-dom";
import Unit from "components/Unit";
import styled from "@emotion/styled";
import Spinner from "components/Spinner";
import { useModuleUnitsQuery } from "hooks/queries";

export default function Module() {
  const params = useParams();
  const unitsQuery = useModuleUnitsQuery(params.module);

  return (
    <MaxWidthWrapper>
      <Container>
        {unitsQuery.isSuccess ? (
          unitsQuery.data.map((unit) => <Unit key={unit.id} unit={unit} />)
        ) : (
          <Spinner />
        )}
      </Container>
    </MaxWidthWrapper>
  );
}

const Container = styled.div`
  padding: 48px 0px 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
