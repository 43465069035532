import styled from "@emotion/styled";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ModuleNavItem from "./ModuleNavItem";

export default function ModuleNav({ modules, overall = false }) {
  const [lastModules, setLastModules] = useLocalStorage(
    "classroom-last-modules",
    {}
  );

  const { program, module } = useParams();
  const navigate = useNavigate();

  function handleModuleChange(module) {
    setLastModules({ ...lastModules, [program]: module });
  }

  useEffect(() => {
    if (!module && !lastModules[program]) navigate(modules[0].slug);
    else if (!module) navigate(lastModules[program], { replace: true });
  }, [lastModules, module, modules, navigate, program]);

  return (
    <Wrapper>
      <MaxWidthWrapper>
        <Container>
          {overall && (
            <ModuleNavItem
              key="overall"
              module={{ slug: "overall", title: "Overall" }}
              onNavClick={handleModuleChange}
            />
          )}
          {modules.map((module) => {
            const disabled = ![
              "onboarding",
              "ruby",
              "html-css",
              "rails",
              "javascript",
              "react",
              "b2-intermediate",
              "b1-pre-intermediate",
              "javascript-intro",
              "html-css-intro",
              "capstone",
            ].includes(module.slug);
            return (
              <ModuleNavItem
                key={module.slug}
                module={module}
                onNavClick={handleModuleChange}
                disabled={disabled}
              />
            );
          })}
        </Container>
      </MaxWidthWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100vw;
  overflow: auto;
  background-color: var(--color-gray-50);
`;

const Container = styled.div`
  display: flex;
  gap: 16px;
  color: var(--color-gray-700);
`;
