import { Outlet, useParams } from "react-router-dom";
import { useModulesQuery } from "hooks/queries";
import ModuleNav from "components/ModuleNav";

export default function MetricsPage() {
  const params = useParams();
  const modulesQuery = useModulesQuery(params.program);

  if (modulesQuery.isLoading) return;

  return (
    <>
      <ModuleNav modules={modulesQuery.data} />
      <Outlet />
    </>
  );
}
