import { Outlet, useParams } from "react-router-dom";
import { useAuth } from "context/auth-context";
import { useModuleUnitsQuery, useStudentProgramQuery } from "hooks/queries";
import UnitNavBar from "components/UnitNavBar";
import NavigationLayout from "components/NavigationLayout";
import Spinner from "components/Spinner";

function LessonLayout() {
  const { user } = useAuth();
  const params = useParams();
  const student = user.student.find((s) => s.cohortSlug[0] === params.cohort);

  const studentProgramQuery = useStudentProgramQuery(
    student.id,
    params.program
  );

  const unitsQuery = useModuleUnitsQuery(params.module);
  const unit = unitsQuery.data?.find((u) => u.slug === params.unit);

  if (studentProgramQuery.isLoading || unitsQuery.isLoading) return <Spinner />;

  return (
    <NavigationLayout aside={<UnitNavBar unit={unit} />}>
      <Outlet />
    </NavigationLayout>
  );
}

export default LessonLayout;
