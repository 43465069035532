import { QUERIES, SIZES, WEIGHTS } from "constants";

import loginHero from "assets/images/login-hero.jpg";
import codeableLogo from "assets/images/codeable-logo.svg";

import styled from "@emotion/styled";

import LoginForm from "./LoginForm";

const Login = () => {
  return (
    <Layout>
      <Container>
        <Wrapper>
          <Logo src={codeableLogo} alt="Codeable Logo" />
          <Title>Sign in to your account</Title>
          <LoginForm />
        </Wrapper>
      </Container>
      <HeroWrapper>
        <HeroImage src={loginHero} alt="" />
      </HeroWrapper>
    </Layout>
  );
};

export default Login;

const Layout = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: 3fr 2fr;

  @media ${QUERIES.tabletAndSmaller} {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-left: 16px;
  padding-right: 16px;
`;

const HeroWrapper = styled.div`
  height: 100%;

  @media ${QUERIES.tabletAndSmaller} {
    display: none;
  }
`;

const HeroImage = styled.img`
  height: 100%;
  object-fit: cover;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  min-width: min(320px, 100%);
`;

const Title = styled.h1`
  font-size: ${SIZES["3xl"]};
  font-weight: ${WEIGHTS.bold};
  text-align: center;
`;

const Logo = styled.img`
  align-self: center;
`;
