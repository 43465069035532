import styled from "@emotion/styled";
import { QUERIES } from "constants";

const MaxWidthWrapper = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;

  @media ${QUERIES.phoneAndSmaller} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export default MaxWidthWrapper;
