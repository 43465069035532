export function getRandomElement(array) {
  const randomIndex = Math.floor(Math.random() * array.length);

  return array[randomIndex];
}

export function mergeUnique(prev = [], ...updates) {
  const map = new Map();

  for (const program of prev.concat(updates.flat())) {
    map.set(program.id, program);
  }

  return Array.from(map.values());
}
