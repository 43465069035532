import { useState } from "react";
import styled from "@emotion/styled";

import { SIZES, WEIGHTS } from "constants";
import { useAuth } from "context/auth-context";
import { COLORS } from "constants";

function LoginForm() {
  const { login, error } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    login({ user }).then(() => setIsLoading(false));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputWrapper>
        <Label>Email:</Label>
        <Input
          onChange={handleChange}
          name="email"
          value={user.username}
          placeholder="testino@mail.com"
        />
      </InputWrapper>
      <InputWrapper>
        <Label>Password:</Label>
        <Input
          onChange={handleChange}
          name="password"
          type="password"
          value={user.password}
          placeholder="******"
        />
      </InputWrapper>
      <Button disabled={isLoading}>{isLoading ? "Loading..." : "Login"}</Button>
      {/* <Forgot>Forgot your password?</Forgot> */}
      {error && <Error>Invalid credentials</Error>}
    </Form>
  );
}

export default LoginForm;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 320px;
`;

const Label = styled.label`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Input = styled.input`
  padding: 8px 16px;
  border: 1px solid var(--color-gray-500);
  border-radius: 8px;
  background-color: var(--color-background);
  &:focus {
    border: 2px solid var(--color-primary);
    outline: none;
  }
`;

const Button = styled.button`
  padding: 8px 16px;
  color: var(--color-white);
  border: none;
  cursor: pointer;
  background: var(--color-primary);
  border-radius: 8px;
  &:focus {
    outline-color: var(--color-primary);
    outline-offset: 4px;
  }
  &:disabled {
    background: hsla(${COLORS.primary}, 0.5);
    cursor: not-allowed;
  }
`;

// const Forgot = styled.a`
//   font-weight: 500;
//   font-size: 14px;
//   line-height: 150%;
//   text-align: center;
//   color: var(--color-tertiary);
// `;

const Error = styled.p`
  color: var(--color-danger);
  text-align: center;
`;
