import styled from "@emotion/styled";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { useLessonCohort, useStudentProgramQuery } from "hooks/queries";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExerciseCard from "./ExerciseCard";
import { OutlineButton } from "components/Button";
import * as S from "./styled";
import CompleteButton from "./CompleteButton";
import MDXContent from "components/MDXContent";
import ContentWrapper from "components/ContentWrapper";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { completeExercise } from "services/airtable";
import GithubButton from "components/GitubButton/GithubButton";

function Exercise({ currentLesson, handleCompleteLesson, isCompleted }) {
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const [currentExercise, setCurrentExercise] = useState();
  const [showSolution, setShowSolution] = useState(false);
  const params = useParams();

  const student = user.student.find((s) => s.cohortSlug[0] === params.cohort);

  useEffect(() => {
    setCurrentExercise(undefined);
  }, [params]);

  const lessonCohortQuery = useLessonCohort(params.cohort, params.module);
  const { data: studentProgram } = useStudentProgramQuery(
    student.id,
    params.program
  );

  const basePath = [params.program, params.module, params.lesson].join("/");
  const currentLessonCohort = lessonCohortQuery.data?.find(
    (item) => item.lesson[0] === currentLesson.id
  );

  const exerciseMutation = useMutation({
    mutationFn: ({ studentProgramId, exerciseId }) =>
      completeExercise(studentProgramId, exerciseId),
    onMutate: async ({ studentProgramId, exerciseId }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: ["student", student.id, "program", params.program],
      });

      // Snapshot the previous value
      const previousStudentProgram = queryClient.getQueryData([
        "student",
        student.id,
        "program",
        params.program,
      ]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        ["student", student.id, "program", params.program],
        (prev) => ({
          ...prev,
          completedExercises: prev.completedExercises
            ? [...prev.completedExercises, exerciseId]
            : [exerciseId],
        })
      );

      // Return a context object with the snapshotted value
      return { previousStudentProgram };
    },
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: ["student", student.id, "program", params.program],
        });
      }, 300);
    },
  });

  return (
    <S.Wrapper>
      <ContentWrapper>
        <TitleWrapper>
          <SubTitle>Exercises</SubTitle>
          <ButtonContainer>
            <GithubButton
              href={currentLessonCohort?.assignmentUrl || ""}
              target="_blank"
            >
              Open Repo
            </GithubButton>
            <CompleteButton
              mutation={handleCompleteLesson}
              isCompleted={isCompleted}
            />
          </ButtonContainer>
        </TitleWrapper>
        <ExercisesGrid>
          {currentLesson.exercises.map((exercise) => {
            return (
              <ExerciseCard
                onClick={() => {
                  setCurrentExercise(exercise);
                  setShowSolution(false);
                }}
                key={exercise.id}
                title={exercise.title}
                description={exercise.description}
                completed={studentProgram.completedExercises?.includes(
                  exercise.id
                )}
              />
            );
          })}
        </ExercisesGrid>
      </ContentWrapper>
      {currentExercise && (
        <>
          <ContentWrapper>
            <MDXContent path={`${basePath}/${currentExercise.slug}`} />
          </ContentWrapper>
          {currentExercise.hasSolution && (
            <>
              <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                <OutlineButton onClick={() => setShowSolution(!showSolution)}>
                  {showSolution ? "Hide solution" : "Show solution"}
                </OutlineButton>
              </div>

              {showSolution && (
                <ContentWrapper>
                  <MDXContent
                    path={`${basePath}/${currentExercise.slug}-solution`}
                  />
                </ContentWrapper>
              )}
            </>
          )}
          <S.Footer>
            <OutlineButton>Submit Feedback</OutlineButton>
            <CompleteButton
              mutation={() =>
                exerciseMutation.mutate({
                  studentProgramId: studentProgram.id,
                  exerciseId: currentExercise.id,
                })
              }
              isCompleted={studentProgram.completedExercises?.includes(
                currentExercise.id
              )}
            />
          </S.Footer>
        </>
      )}
    </S.Wrapper>
  );
}

export default Exercise;

const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const SubTitle = styled.h2`
  font-size: ${SIZES["5xl"]};
  font-weight: ${WEIGHTS.bold};
`;

const ExercisesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 16px;
  margin-top: 40px;
`;
