import styled from "@emotion/styled";
import { COLORS } from "constants";
import { QUERIES } from "constants";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import LessonList from "./../LessonList";

const Unit = ({ unit }) => {
  return (
    <Wrapper>
      <UnitContent>
        <Week>{unit.label}</Week>
        <Title>{unit.title}</Title>
        <Description>{unit.description}</Description>
      </UnitContent>
      <List>
        <LessonList lessons={unit.lessons} />
      </List>
    </Wrapper>
  );
};
export default Unit;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 48px;
  @media ${QUERIES.phoneAndSmaller} {
    gap: 16px;
  }
`;
const UnitContent = styled.div`
  max-width: 350px;
  padding-right: 3rem;
`;
const Week = styled.p`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.bold};
  color: hsla(${COLORS.tertiary});
`;
const Title = styled.p`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
  color: hsla(${COLORS.text});
  margin-bottom: 1rem;
`;
const Description = styled.p`
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.light};
  color: hsla(${COLORS.text});
`;
const List = styled.div`
  max-width: 320px;
  width: 100%;
`;
