import ModuleNav from "components/ModuleNav";
import { useModulesQuery } from "hooks/queries";
import { Outlet, useParams } from "react-router-dom";

export default function ModulesPage() {
  const params = useParams();
  const modulesQuery = useModulesQuery(params.program);

  if (modulesQuery.isLoading) return;

  return (
    <>
      <ModuleNav modules={modulesQuery.data} />
      <Outlet />
    </>
  );
}
