import Spinner from "components/Spinner";
import { useAuth } from "context/auth-context";
import UnauthenticatedApp from "UnauthenticatedApp";
import AuthenticatedApp from "./AuthenticatedApp";

function App() {
  const { user, loading } = useAuth();

  if (loading) return <Spinner />;
  return user ? <AuthenticatedApp /> : <UnauthenticatedApp />;
}

export default App;
