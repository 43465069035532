import MaxWidthWrapper from "components/MaxWidthWrapper";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import Spinner from "components/Spinner";
import { useEventParticipationQuery } from "hooks/queries";
import { useAuth } from "context/auth-context";
import InvolvementCard from "components/InvolvementCard";
import { SIZES } from "constants";
import { WEIGHTS } from "constants";

export default function ModuleMetric() {
  const { user } = useAuth();
  const params = useParams();
  const student = user.student.find((s) => s.cohortSlug[0] === params.cohort);
  const eventParticipationQuery = useEventParticipationQuery(
    student.id,
    params.module
  );

  return (
    <MaxWidthWrapper>
      <Container>
        {eventParticipationQuery.isSuccess ? (
          <div>
            <MetricTitle>Involvement</MetricTitle>
            <InvolvementCard events={eventParticipationQuery.data} />
          </div>
        ) : (
          <Spinner />
        )}
      </Container>
    </MaxWidthWrapper>
  );
}

const Container = styled.div`
  padding: 48px 0px 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const MetricTitle = styled.h2`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
  margin-bottom: 16px;
  color: var(--color-text);
`;
