import { Outlet, useParams } from "react-router-dom";
import ProgramNav from "components/ProgramNav";
import ProgramInfo from "components/ProgramInfo";
import { useProgramQuery } from "hooks/queries";

export default function ProgramPage() {
  const params = useParams();
  const { data: program } = useProgramQuery(params.program);

  return (
    <>
      <ProgramInfo program={program} />
      <ProgramNav />
      <Outlet />
    </>
  );
}
