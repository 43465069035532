import { PrimaryButton } from "components/Button";
import { COLORS } from "constants";
import { CheckCircle } from "react-feather";
import { SpinnerCircular } from "spinners-react";

export default function CompleteButton({ mutation, isCompleted }) {
  return (
    <PrimaryButton onClick={mutation} disabled={isCompleted}>
      {mutation.isLoading ? (
        <>
          <span>Completing...</span>
          <SpinnerCircular size={16} color={`hsl(${COLORS.white})`} />
        </>
      ) : (
        <>
          <span>{isCompleted ? "Completed" : "Mark as completed"}</span>
          <CheckCircle height={18} style={{ marginLeft: "8px" }} />
        </>
      )}
    </PrimaryButton>
  );
}
