import React, { Suspense, lazy } from "react";
import { components } from "./components";
import "@code-hike/mdx/dist/index.css";
import "./code-hike-override.css";
import Spinner from "components/Spinner";

function MDXContent({ path }) {
  const Content = lazy(() => import(`/src/curriculum/${path}.mdx`));

  return (
    <Suspense fallback={<Spinner />}>
      <Content components={components} />
    </Suspense>
  );
}

export default React.memo(MDXContent);
