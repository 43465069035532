import styled from "@emotion/styled";
import { COLORS, SIZES, WEIGHTS } from "constants";
import { ArrowRight } from "react-feather";
import programCardImage from "assets/images/programCardImage.svg";
import englishImg from "assets/images/english.svg";
import employabilityImg from "assets/images/employability.svg";
import defaultImg from "assets/images/default.svg";
import { Link, useNavigate } from "react-router-dom";

const programImages = {
  "full-stack-web-dev": programCardImage,
  english: englishImg,
  employability: employabilityImg,
  default: defaultImg,
};

function ProgramCard({
  cohort,
  title,
  shortDescription,
  slug,
  path,
  currentLesson,
}) {
  const programImage = programImages[slug] || programImages["default"];
  const navigate = useNavigate();

  function handleClick(event, to) {
    event.preventDefault();
    navigate(to);
  }

  return (
    <Wrapper to={path}>
      <CardImage src={programImage} alt="programCardImage" />
      <TitleCard>{title}</TitleCard>
      <BodyCard>{shortDescription}</BodyCard>

      {currentLesson && (
        <ContinueButton
          onClick={(event) =>
            handleClick(event, `${cohort.slug}/${currentLesson.path}`)
          }
        >
          <TextFooter>
            <TitleFooter>Continue Learning</TitleFooter>
            <BodyFooter>{currentLesson.title}</BodyFooter>
          </TextFooter>
          <ArrowRight />
        </ContinueButton>
      )}
    </Wrapper>
  );
}

export default ProgramCard;

const Wrapper = styled(Link)`
  text-decoration: none;
  max-width: 300px;
  border-radius: 0.5rem;
  padding: 3rem 1rem 1rem 1rem;
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
  border: hsla(${COLORS.primary}) 2px solid;
  margin-top: 65px;
  position: relative;
  background-color: hsla(${COLORS.secondary}, 0.25);
  box-shadow: 0.5px 0.5px 0.8px rgba(150, 150, 150, 0.41),
    2px 1.9px 3.3px rgba(150, 150, 150, 0.52); ;
`;

const CardImage = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  top: -60px;
  margin: 0 auto;
`;

const TitleCard = styled.h3`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
  margin-bottom: 0.25rem;
  text-decoration: none;

  &:hover {
    text-decoration: revert;
  }
`;

const BodyCard = styled.div`
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.light};
  margin-bottom: 1rem;
`;

const ContinueButton = styled.button`
  display: flex;
  width: 100%;
  border: none;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.light};
  background-color: var(--color-primary);
  color: hsla(${COLORS.white});
  border-radius: 8px;
  padding: 0.5rem 1rem;
  text-align: left;

  &:hover {
    background-color: hsla(${COLORS.primary}, 0.9);
  }
`;

const TextFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TitleFooter = styled.div`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
`;

const BodyFooter = styled.div`
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.medium};
`;
