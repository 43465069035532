import styled from "@emotion/styled";
import { Label } from "components/Inputs/Input";
import { Options } from ".";

export function Select({ options, children, register, label }) {
  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <StyledSelect {...register}>
        {options ? <Options options={options} /> : children}
      </StyledSelect>
    </SelectWrapper>
  );
}

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledSelect = styled.select`
  border-radius: 8px;
  border: 1px solid var(--color-gray-500);
  padding: 8px 16px;
  height: 42px;
`;
