import styled from "@emotion/styled";
import { QUERIES } from "constants";

function ContentWrapper({ children }) {
  return <Wrapper>{children}</Wrapper>;
}

export default ContentWrapper;

const Wrapper = styled.div`
  display: grid;
  padding: 64px 32px;
  grid-template-columns: 1fr min(750px, 100%) 1fr;
  background-color: var(--color-background);
  border-radius: 8px;
  margin: 16px;

  & > * {
    grid-column-start: 2;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  @media ${QUERIES.tabletAndSmaller} {
    padding: 32px 16px;
  }
`;
