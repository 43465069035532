import styled from "@emotion/styled";
import { Menu } from "@headlessui/react";
import { ChevronDown, User, LogOut } from "react-feather";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import codeableLogo from "assets/images/codeable-logo.svg";
import { COLORS, QUERIES, SIZES, WEIGHTS } from "constants";
import { useAuth } from "context/auth-context";
import { Link } from "react-router-dom";

function Header() {
  const { logout, user } = useAuth();

  function handleLogout() {
    logout();
    window.location.reload();
  }

  return (
    <header>
      <Wrapper>
        <Link to="/">
          <Logo src={codeableLogo} alt="Codeable Logo" />
        </Link>
        <MenuWrapper as="div">
          <MenuButton>
            <Avatar src={user.avatar[0].url} />
            <ChevronDown />
          </MenuButton>
          <MenuItems>
            <Menu.Item>
              <LinkWrapper to="/profile/personal">
                <User color={`hsl(${COLORS.primary})`} />
                <LinkContent>
                  <LinkTitle>Profile</LinkTitle>
                  <LinkDescription>View or edit your profile</LinkDescription>
                </LinkContent>
              </LinkWrapper>
            </Menu.Item>

            <Menu.Item>
              <LinkWrapper as="button" onClick={handleLogout}>
                <LogOut color={`hsl(${COLORS.primary})`} />
                <LinkContent>
                  <LinkTitle>Log out</LinkTitle>
                  <LinkDescription>See you later alligator</LinkDescription>
                </LinkContent>
              </LinkWrapper>
            </Menu.Item>
          </MenuItems>
        </MenuWrapper>
      </Wrapper>
    </header>
  );
}

export default Header;

const Wrapper = styled(MaxWidthWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
`;

const Logo = styled.img`
  height: 32px;

  @media (${QUERIES.phoneAndSmaller}) {
    height: 24px;
  }
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
`;

const MenuWrapper = styled(Menu)`
  position: relative;
`;

const MenuButton = styled(Menu.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const MenuItems = styled(Menu.Items)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background-color: var(--color-background);
  min-width: max-content;
  border: 1px solid hsl(${COLORS.gray[200]});
  border-radius: 8px;
  box-shadow: 0.5px 0.5px 0.8px rgba(150, 150, 150, 0.41),
    2px 1.9px 3.3px rgba(150, 150, 150, 0.52);

  position: absolute;
  right: 0;
  margin-top: 8px;
`;

const LinkWrapper = styled(Link)`
  display: flex;
  border: none;
  text-align: left;
  background: none;
  gap: 8px;
  padding: 8px;
  align-items: flex-start;
  text-decoration: none;
  border-radius: 8px;

  &:hover,
  &[data-headlessui-state="active"] {
    background-color: hsla(${COLORS.secondary}, 0.5);
  }
`;

const LinkContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkTitle = styled.span`
  font-weight: ${WEIGHTS.medium};
  font-size: ${SIZES.lg};
`;

const LinkDescription = styled.span`
  font-weight: ${WEIGHTS.light};
  font-size: ${SIZES.sm};
`;
