import styled from "@emotion/styled";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";

export function Input({
  label,
  placeholder,
  register,
  type = "text",
  error,
  ...delegated
}) {
  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <StyledInput
        type={type}
        placeholder={placeholder}
        {...register}
        {...delegated}
      />
      {error && <Error>{error.message}</Error>}
    </InputWrapper>
  );
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;
export const Label = styled.label`
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
`;
const StyledInput = styled.input`
  border-radius: 8px;
  border: 1px solid var(--color-gray-500);
  padding: 8px 16px;
`;
const Error = styled.p`
  color: var(--color-tertiary);
  padding-left: 1rem;
`;
