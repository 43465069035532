import { tokenKey } from "config";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getUserByEmail, updateStudentPrograms } from "services/airtable";
import { signIn } from "services/auth-service";
import { getUser, updateUser } from "services/user-service";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem(tokenKey);
    if (token) {
      getUser()
        .then((user) => {
          setError(null);
          return getUserByEmail(user.email);
        })
        .then((user) => {
          setUser(user);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setError(err.message);
        });
    } else {
      setLoading(false);
    }
  }, []);

  const login = useCallback(function (credentials) {
    setLoading(true);
    return signIn(credentials)
      .then((user) => getUserByEmail(user.email))
      .then((user) => {
        updateStudentPrograms([user.student[0]]).then(() => {
          setUser(user);
          setLoading(false);
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(err);
      });
  }, []);

  const logout = useCallback(function () {
    sessionStorage.removeItem(tokenKey);
    // signOut().then(() => {
    //   sessionStorage.removeItem(tokenKey);
    //   setUser(null);
    // });
  }, []);

  const updateCredentials = useCallback(function (email, password) {
    return updateUser({ user: { email, password } });
  }, []);

  const updateProfile = useCallback(function (newData) {
    setUser((current) => {
      return { ...current, ...newData };
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        loading,
        updateCredentials,
        updateProfile,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthProvider, useAuth };
