import NavigationLayout from "components/NavigationLayout";
import ProfileNav from "components/ProfileNav";
import { Outlet } from "react-router-dom";

function ProfileLayout() {
  return (
    <NavigationLayout aside={<ProfileNav />}>
      <Outlet />
    </NavigationLayout>
  );
}

export default ProfileLayout;
