import { tokenKey } from "../config";
import apiFetch from "./api-fetch";

export function createUser(userData) {
  return apiFetch("/users", { body: userData }).then((data) => {
    const { token, ...user } = data.user;
    sessionStorage.setItem(tokenKey, token);
    return user;
  });
}
export function getUser() {
  return apiFetch("/user").then((data) => {
    const { token, ...user } = data.user;
    return user;
  });
}
export function updateUser(userData) {
  return apiFetch("/user", { body: userData, method: "PATCH" }).then((data) => {
    const { token, ...user } = data.user;
    return user;
  });
}
