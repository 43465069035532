import { COLORS } from "constants";
import { SpinnerDotted } from "spinners-react";

function Spinner({ size = 32 }) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "grid",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <SpinnerDotted size={size} color={`hsl(${COLORS.primary})`} />
    </div>
  );
}

export default Spinner;
