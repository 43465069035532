import { PrimaryButton } from "components/Button";
import { Input, Label } from "components/Inputs/Input";
import { QUERIES } from "constants";
import { Title } from "./PersonalProfileForm";
import styled from "@emotion/styled";
import { useAuth } from "context/auth-context";
import { useState } from "react";

export function ClassroomCredentialsForm() {
  const { user, updateCredentials } = useAuth();
  const [email, setEmail] = useState(user.email || "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    updateCredentials(email, password).then(() => {
      setLoading(false);
      setPassword("");
    });
  }

  return (
    <CredentialsWrapper>
      <Container>
        <div>
          <Title>Classroom Credentials</Title>
          <Label>
            If you need to update your email, please contact the education team
            🙏.
          </Label>
        </div>

        <Form onSubmit={handleSubmit}>
          <Row cols={3}>
            <Input
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </Row>
          <Row cols={3}>
            <Input
              label="Password"
              type="password"
              placeholder="new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
          <Row cols={6} justify="end">
            <PrimaryButton disabled={loading || password === ""}>
              {loading ? "Loading..." : "Save"}
            </PrimaryButton>
          </Row>
        </Form>
      </Container>
    </CredentialsWrapper>
  );
}

const Form = styled.form`
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(6, 1fr);
`;
const CredentialsWrapper = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: var(--color-white);
`;

const Container = styled.div`
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Row = styled.div`
  grid-column: span ${({ cols }) => cols};
  justify-self: ${({ justify = "unset" }) => justify};

  @media ${QUERIES.tabletAndSmaller} {
    grid-column: span 6;
  }
`;
