import { NavLink, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { ReactComponent as Ruby } from "assets/icons/ruby-icon.svg";
import { ReactComponent as Rails } from "assets/icons/rails-icon.svg";
import { ReactComponent as Html } from "assets/icons/html-css-icon.svg";
import { ReactComponent as Javascript } from "assets/icons/javascript-icon.svg";
import { ReactComponent as ReactIcon } from "assets/icons/react-icon.svg";
import { BookOpen, Codesandbox, Compass, Info } from "react-feather";
import { WEIGHTS } from "constants";
import { COLORS } from "constants";

const icons = {
  overall: BookOpen,
  onboarding: Compass,
  ruby: Ruby,
  rails: Rails,
  "html-css": Html,
  javascript: Javascript,
  react: ReactIcon,
  capstone: Codesandbox,
  "javascript-intro": Javascript,
  "html-css-intro": Html,
};

function ModuleNavItem({ module, onNavClick, disabled }) {
  const params = useParams();

  const Icon = icons[module.slug] || Info;
  return (
    <NavLinkStyled
      to={disabled ? "./" : params.view ? `../${module.slug}` : module.slug}
      onClick={() => !disabled && onNavClick(module.slug)}
      disabled={disabled}
    >
      <Icon />
      <span>{module.title}</span>
    </NavLinkStyled>
  );
}

const NavLinkStyled = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  padding: 12px 8px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  font-weight: ${WEIGHTS.medium};
  flex-shrink: 0;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:any-link,
  &:-webkit-any-link {
    color: ${({ disabled }) =>
      disabled ? `hsla(${COLORS.text}, 0.5)` : "inherit"};
  }

  &.active {
    color: var(--color-text);
    border-bottom: 2px solid var(--color-primary);
  }

  &:hover {
    color: var(--color-primary);
  }
`;

export default ModuleNavItem;
