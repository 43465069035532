import styled from "@emotion/styled";
import { WEIGHTS } from "constants";
import { SIZES } from "constants";
import { COLORS } from "constants";

export default function ExerciseCard({
  title,
  description,
  completed,
  onClick,
}) {
  return (
    <ExerciseWrapper onClick={onClick}>
      <ExerciseHeader>
        <ExerciseTitle>{title}</ExerciseTitle>
        {completed && <StatusPill status="completed">Completed</StatusPill>}
      </ExerciseHeader>
      <ExerciseDescription>{description}</ExerciseDescription>
    </ExerciseWrapper>
  );
}

const ExerciseWrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 12px 16px;
  border-radius: 8px;
  border: 2px solid var(--color-primary);
  cursor: pointer;
  background-color: var(--color-white);
  text-align: left;

  &:hover,
  &:focus {
    background-color: hsla(${COLORS.primary}, 0.1);
  }
`;

const ExerciseTitle = styled.div`
  font-size: ${SIZES.lg};
  font-weight: ${WEIGHTS.medium};
`;

const ExerciseDescription = styled.div`
  margin-top: 8px;
  font-size: ${SIZES.md};
  font-weight: ${WEIGHTS.light};
`;

const statusStyles = {
  completed: {
    bg: "var(--color-primary)",
    color: "var(--color-white)",
  },
  inProgress: {
    bg: "var(--color-secondary)",
    color: "var(--color-text)",
  },
  notStarted: {
    bg: "var(--color-gray-100)",
    color: "var(--color-text)",
  },
};

const StatusPill = styled.span`
  background-color: ${({ status = "notStarted" }) => statusStyles[status].bg};
  color: ${({ status = "notStarted" }) => statusStyles[status].color};
  padding: 2px 8px;
  font-size: ${SIZES.sm};
  font-weight: ${WEIGHTS.light};
  border-radius: 16px;
`;

const ExerciseHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
