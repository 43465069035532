import styled from "@emotion/styled";
import { Button } from "components/Button";
import { ReactComponent as Github } from "assets/icons/mark-github.svg";

function GithubButton({ children, ...delegated }) {
  const StyledButton = styled(Button)`
    color: var(--color-white);
    background-color: #2da44e;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    &:any-link,
    &:-webkit-any-link {
      color: var(--color-white);
      text-decoration: none;
    }
  `;

  return (
    <StyledButton as="a" {...delegated}>
      {children}
      <Github />
    </StyledButton>
  );
}

export default GithubButton;
