import styled from "@emotion/styled";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "context/auth-context";
import { COLORS, SIZES, WEIGHTS } from "constants";
import ProgramCard from "components/Card";
import MaxWidthWrapper from "components/MaxWidthWrapper";
import Quote from "components/Quote";

function time() {
  const hour = new Date().getHours();
  if (hour >= 5 && hour <= 12) return "morning";
  if (hour <= 18) return "afternoon";
  return "evening";
}

function Dashboard() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const currentLessons =
    JSON.parse(localStorage.getItem("classroom-current-lessons")) || {};

  return (
    <MaxWidthWrapper>
      <GreetingWrapper>
        <h2>
          Good {time()} {user.preferredName}!
        </h2>
        <Quote />
      </GreetingWrapper>

      {user.activeCohorts.map((cohort) => {
        return (
          <div key={cohort.id}>
            <p style={{ marginBottom: "16px" }}>{cohort.name} Programs:</p>
            <CardWrapper>
              {cohort.programsSlugs.map((programSlug) => {
                const program = queryClient.getQueryData({
                  queryKey: ["programs", programSlug],
                });
                const { title, shortDescription, slug } = program;
                return (
                  <ProgramCard
                    key={slug}
                    cohort={cohort}
                    title={title}
                    shortDescription={shortDescription}
                    slug={slug}
                    path={`${cohort.slug}/${slug}/modules`}
                    currentLesson={currentLessons[slug]}
                  />
                );
              })}
            </CardWrapper>
          </div>
        );
      })}
    </MaxWidthWrapper>
  );
}

export default Dashboard;

const GreetingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: hsl(${COLORS.text});
  margin-top: 64px;
  margin-bottom: 32px;
  h2 {
    font-size: ${SIZES["2xl"]};
    font-weight: ${WEIGHTS.bold};
  }
`;

const CardWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(min(300px, 100%), 1fr));
  column-gap: 16px;
  row-gap: 32px;
  margin-bottom: 64px;
`;
